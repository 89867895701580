export default function publikacje_init() {
  const publikacjeSlider = document.querySelector('.publikacje-slider')
  if(!publikacjeSlider) return
  $(publikacjeSlider).slick({
    arrows: false,
    dots: true
  })

  $('.publikacje-slider2').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  })

  $('#slider2-nav .next').click(() => {
    $('.publikacje-slider2').slick('slickNext')
  })
  $('#slider2-nav .prev').click(() => {
    $('.publikacje-slider2').slick('slickPrev')
  })

  $('.publikacje-slider3').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  })

  $('#slider3-nav .next').click(() => {
    $('.publikacje-slider3').slick('slickNext')
  })
  $('#slider3-nav .prev').click(() => {
    $('.publikacje-slider3').slick('slickPrev')
  })

}