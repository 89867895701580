import { onAfterChange } from "./utils"

export default function case2_init() {
  const slider1 = document.querySelector('.case2-big-slider')
  const slider2 = document.querySelector('.case2-slider2')
  if(!slider1) return
  $(slider1).slick({
    items: 1,
  })
  $(slider2).slick({
    items: 1,
  })

  $(slider1).on('afterChange', (slick, slide) => {
    onAfterChange(slider1, slide.currentSlide)
  })
  $(slider2).on('afterChange', (slick, slide) => {
    onAfterChange(slider2, slide.currentSlide)
  })
}