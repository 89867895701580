import { screen, onAfterChange } from './utils'

export default function home_box_animation_init() {
  const slide1 = document.querySelector('.magic-slider .slide-1')
  const slide2 = document.querySelector('.magic-slider .slide-2')

  if(slide1 && slide2) {
    gsap.registerPlugin(MotionPathPlugin)

    var currentSlider = slide2
    var canClick = true

    function percent(element, x, y, scale = 1) {
      const rect = element.getBoundingClientRect()
      const px = parseFloat(x.replace('%', '')) / 100
      const py = parseFloat(y.replace('%', '')) / 100
      return {
        x: rect.width * scale * px,
        y: rect.height * scale * py
      }
    }

    function onSliderClick(className) {
      if(!canClick) return
      if(document.querySelector('.magic-slider ' + className).classList.contains('active')) return;
      canClick = false
      if(screen("md", "xxl")) {
        if(currentSlider == slide2) {
          var t1 = gsap.timeline()
          t1.to(slide1, {
            motionPath: {
              path: [
                percent(slide1, '5%', '0%'),
                percent(slide1, '20%', '0%'),
                percent(slide1, '80%', '-10%'),
                percent(slide1, '90%', '-30%')
              ],
              type: 'cubic'
            },
            scale: 0.9,
            ease: 'sine.in',
            onComplete: function() {
              slide1.classList.remove('active')
            }
          })
          t1.to(slide1,{
            zIndex: 0,
            duration: 0
          })
          t1.to(slide1, {
            motionPath: {
              path: [
                percent(slide1, '90%', '-30%'),
                percent(slide1, '60%', '-30%'),
                percent(slide1, '30%', '-40%'),
                percent(slide1, '28%', '-40%')
              ],
              type: 'cubic'
            },
            scale: 0.73,
            ease: 'power1.out'
          })

          var t2 = gsap.timeline()
          t2.to(slide2, {
            motionPath: {
              path: [
                percent(slide2, '0%', '-8.5%'),
                percent(slide2, '-10%', '-7%'),
                percent(slide2, '-20%', '-4%'),
                percent(slide2, '-14%', '-2%'),
              ],
              type: 'cubic'
            },
            scale: 0.9,
            ease: 'sine.in',
            onComplete: function() {
              slide2.classList.add('active')
            }
          })
          t2.to(slide2,{
            zIndex: 1,
            duration: 0
          })
          t2.to(slide2, {
            motionPath: {
              path: [
                percent(slide2, '-14%', '-3%'),
                percent(slide2, '-12%', '-2%'),
                percent(slide2, '-6%', '0%'),
                percent(slide2, '0%', '0%'),
              ],
              type: 'cubic'
            },
            scale: 1,
            ease: 'power1.out',
            onComplete: function() {
              canClick = true
            }
          })
          currentSlider = slide1
        } else {
          var t1 = gsap.timeline()
          t1.to(slide2, {
            motionPath: {
              path: [
                percent(slide2, '0%', '0%'),
                percent(slide2, '10%', '0%'),
                percent(slide2, '20%', '-2%'),
                percent(slide2, '14%', '-3%'),
              ],
              type: 'cubic'
            },
            scale: 0.9,
            ease: 'power1.in',
            onComplete: function() {
              slide2.classList.remove('active')
            }
          })
          t1.to(slide2,{
            zIndex: 0,
            duration: 0
          })
          t1.to(slide2, {
            motionPath: {
              path: [
                percent(slide2, '14%', '-3%'),
                percent(slide2, '10%', '-4%'),
                percent(slide2, '8%', '-6%'),
                percent(slide2, '4.37%', '-6.25%'),
              ],
              type: 'cubic'
            },
            scale: 0.73,
            ease: 'power1.out'
          })

          var t2 = gsap.timeline()
          t2.to(slide1, {
            motionPath: {
              path: [
                percent(slide1, '28%', '-55%'),
                percent(slide1, '0%', '-50%'),
                percent(slide1, '-100%', '-40%'),
                percent(slide1, '-120%', '-30%'),
              ],
              type: 'cubic'
            },
            scale: 0.9,
            ease: 'power1.in',
            onComplete: function() {
              slide1.classList.add('active')
            }
          })
          t2.to(slide1,{
            zIndex: 1,
            duration: 0
          })
          t2.to(slide1, {
            motionPath: {
              path: [
                percent(slide1, '-120%', '-30%'),
                percent(slide1, '-70%', '-20%'),
                percent(slide1, '-40%', '-10%'),
                percent(slide1, '0%', '0%'),
              ],
              type: 'cubic'
            },
            scale: 1,
            ease: 'power1.out',
            onComplete: function() {
              canClick = true
            }
          })
          currentSlider = slide2
        }
      } else {
         ////////////////////////////////////////////////////////////////////////////////////////////
        if(currentSlider == slide2) {
          var t1 = gsap.timeline();
          t1.to(slide1, {
            x: '85vw',
            y: '-20vw',
            scale: 0.9,
            ease: 'sine.in',
            onComplete: function() {
              slide1.classList.remove('active')
            }
          })
          t1.to(slide1,{
            zIndex: 0,
            duration: 0
          })
          t1.to(slide1, {
            x: '20vw',
            y: '-40vw',
            scale: 0.8,
            ease: 'power1.out'
          })

          var t2 = gsap.timeline();
          console.log('work')
          t2.to(slide2, {
            x: '-50vw',
            y: '-20vw',
            scale: 0.9,
            ease: 'sine.in',
            onComplete: function() {
              slide2.classList.add('active')
            }
          })
          t2.to(slide2,{
            zIndex: 1,
            duration: 0
          })
          t2.to(slide2, {
            x: 0,
            y: 0,
            scale: 1,
            ease: 'power1.out',
            onComplete: function() {
              canClick = true
            }
          })
          currentSlider = slide1
        } else {
          var t1 = gsap.timeline();
          t1.to(slide2, {
            x: '85vw',
            y: '-20vw',
            scale: 0.9,
            ease: 'power1.in',
            onComplete: function() {
              slide2.classList.remove('active')
            }
          })
          t1.to(slide2,{
            zIndex: 0,
            duration: 0
          })
          t1.to(slide2, {
            x: '20vw',
            y: '-40vw',
            scale: 0.8,
            ease: 'power1.out'
          })

          var t2 = gsap.timeline();
          t2.to(slide1, {
            x: '-50vw',
            y: '-20vw',
            scale: 0.9,
            ease: 'power1.in',
            onComplete: function() {
              slide1.classList.add('active')
            }
          })
          t2.to(slide1,{
            zIndex: 1,
            duration: 0
          })
          t2.to(slide1, {
            x: 0,
            y: 0,
            scale: 1,
            ease: 'power1.out',
            onComplete: function() {
              canClick = true
            }
          })
          currentSlider = slide2
        }
      }
    }

    if(screen("md", "xxl")) {
      gsap.to(slide2, {
        x: '28%',
        y: '-40%',
        zIndex: 0,
        scale: 0.73,
      })
    } else {
      gsap.to(slide2, {
        x: '20vw',
        y: '-40vw',
        zIndex: 0,
        scale: 0.8,
      })
    }
    
    slide1.addEventListener('click', function() {
      onSliderClick('.slide-1')
    });
    slide2.addEventListener('click', function() {
      onSliderClick('.slide-2')
    });
  }

  const miniSlider1 = $('.mini-slider-1')
  const miniSlider2 = $('.mini-slider-2')
  const miniSliderConfig = {
    itemsToShow: 1,
    itemsToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false
  }

  miniSlider1.slick({
    ...miniSliderConfig,
    autoplaySpeed: 3612
  })
  miniSlider2.slick({
    ...miniSliderConfig,
    autoplaySpeed: 4083
  })

  miniSlider1.on('afterChange', (slick, slide) => {
    onAfterChange(miniSlider1, slide.currentSlide)
  })
  miniSlider2.on('afterChange', (slick, slide) => {
    onAfterChange(miniSlider2, slide.currentSlide)
  })
}