import {isElement, screen, scrollToLeftEdge} from './utils'

export default function agency_init() {

  // Slider 3
  const navButtons2 = document.querySelectorAll('.agency-slider-3 .agency-slider-3-nav-link')
  navButtons2.forEach(element => {
    element.addEventListener('click', function(e) {
      const id = this.href.split('#')[1]
      const activeSlide = document.querySelector('.agency-slider-3-track .agency-slider-3-slide.active')
      activeSlide.classList.remove('active')
      const newSlide = document.getElementById(id)
      newSlide.classList.add('active')
      const activeButton = document.querySelector('.agency-slider-3-nav-list .agency-slider-3-nav-link.active')
      activeButton.classList.remove('active')
      this.classList.add('active')
      e.preventDefault()
    })
  })

  // Slider 1
  const navButtons = document.querySelectorAll('.agency-slider-navigation .agency-slider-nav-link')
  if(!navButtons.length) return
  const buttonPrev = document.querySelector('.agency-slider-navigation .agency-slider-nav-button.prev')
  const buttonNext = document.querySelector('.agency-slider-navigation .agency-slider-nav-button.next')

  const maxTabs = navButtons.length
  var currentTab = 1

  function changeTab(number) {
    let link = undefined
    if(Number.isInteger(number)) {
      link = document.querySelector('.agency-slider-navigation .agency-slider-nav-link[data-order="'+number+'"]')
    }
    else if(isElement(number)) {
      link = number
    }
    else {
      console.error('Invalid argument number. Expected type: Number or DOMElement')
      return
    }

    const id = link.href.split('#')[1]
    const activeSlide = document.querySelector('.agency-slider-track .agency-slider-slide.active')
    activeSlide.classList.remove('active')
    const newSlide = document.getElementById(id)
    newSlide.classList.add('active')
    const activeButton = document.querySelector('.agency-slider-nav-list .agency-slider-nav-link.active')
    activeButton.classList.remove('active')
    link.classList.add('active')
    currentTab = Number(link.dataset.order)
    if(screen("xs", "md")) {
      const rect = link.getBoundingClientRect();
      const position = rect.top + window.pageYOffset - 128;
      window.scrollTo({ top: position, behavior: 'smooth' });
      scrollToLeftEdge(link.parentNode)
    }
  }

  navButtons.forEach(element => {
    element.addEventListener('click', function(e) {
      changeTab(this)
      e.preventDefault()
    })
  })

  function prevTab() {
    if(currentTab > 1) {
      changeTab(currentTab - 1)
    } else {
      changeTab(maxTabs)
    }
  }

  function nextTab() {
    if(currentTab < maxTabs) {
      changeTab(currentTab + 1)
    } else {
      changeTab(1)
    }
  }

  buttonPrev.addEventListener('click', prevTab)
  buttonNext.addEventListener('click', nextTab)

  const agencySliderTrackElm = document.querySelector('.agency-slider-track')
  if(agencySliderTrackElm && screen("xs", "lg")) {
    let touchstartX = 0;
    agencySliderTrackElm.addEventListener('touchstart', function(event) {
      touchstartX = event.touches[0].clientX;
    }, false);

    agencySliderTrackElm.addEventListener('touchend', function(event) {
      const touchendX = event.changedTouches[0].clientX;
      const diffX = touchendX - touchstartX;

      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          prevTab();
        } else {
          nextTab();
        }
      }
    }, false);
  }


  // Slider 2
  const agency_slider_2 = $('.agency-slider-2 .slick-slider')
  agency_slider_2.slick({
    items: 1,
    dots: true,
    arrows: false,
    fade: true
  })
  const logos = $('.logos')
  logos.slick({
    centerMode: false,
    variableWidth: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: false,
  });
}