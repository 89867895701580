export default function team_init() {
  const teamSlider = document.querySelector('.team-slider')
  if(!teamSlider) return

  const prevButton = $('.team-slider-nav .prev')
  const nextButton = $('.team-slider-nav .next')

  const teamSliderControl = $(teamSlider).slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  })

  prevButton.click(() => {
    teamSliderControl.slick('slickPrev')
  })

  nextButton.click(() => {
    teamSliderControl.slick('slickNext')
  })
}