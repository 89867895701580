import { onAfterChange } from "./utils"

export default function case1_init() {
  const slider1 = document.querySelector('.case1-section2 .subsection2 .slider-1')
  const slider2 = document.querySelector('.case1-section2 .subsection2 .slider-2')
  if(!slider1) return

  $(slider1).slick({
    items: 1,
    arrows: false,
    fade: true,
  })

  $(slider2).slick({
    items: 1,
    arrows: false,
    asNavFor: slider1,
    dots: true,
  })

  $(slider2).on('afterChange', (slick, slide) => {
    onAfterChange(slider2, slide.currentSlide)
  })
}