export default function home_init() {
  const homeSlider = $('.linked-columns-slider')
  if(homeSlider.length) {
    const homeSliderControl = homeSlider.slick({
      slidesToShow: 5,
      slidesToScroll: 5,
      infinite: false,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        }
      ]
    })
  }

  $('.home-section-5 .articles').slick({
    slidesToShow: 5,
    arrows: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      },
    ]
  })
}