import navigation from './navigation'
import home_box_animation from './home_box_animation'
import agency from './agency'
import case1 from './case1'
import case2 from './case2'
import team from './team'
import publikacje from './publikacje'
import home from './home'
import shop from './shop'
import wydawnictwo from './wydawnictwo'
import gutenberg_gallery from './gutenberg_gallery'

navigation()
home()
home_box_animation()
agency()
case1()
case2()
team()
publikacje()
shop()
wydawnictwo()
gutenberg_gallery()